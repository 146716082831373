import React from "react";
import {Card, CardHeader, CardBody, CardFooter, Image, Button} from "@nextui-org/react";
import { useNavigate } from "react-router-dom";

export default function HomePage() {
  const navigate=useNavigate();
  return (
    <div className="gap-2 grid grid-cols-12 grid-rows-2 container">
    <Card className="col-span-12 sm:col-span-4 h-[300px]" isPressable onPress={() => navigate("account")}>
      <CardHeader className="absolute z-10 top-1 flex-col !items-start">
      <h4 className="text-white font-medium text-large">Account</h4>
      </CardHeader>
      <Image
        removeWrapper
        alt="Card background"
        className="z-0 w-full h-full object-cover"
        src="https://images.unsplash.com/photo-1603539947678-cd3954ed515d?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      />
    </Card>
    <Card className="col-span-12 sm:col-span-4 h-[300px]">
      <CardHeader className="absolute z-10 top-1 flex-col !items-start">
        <p className="text-tiny text-white/60 uppercase font-bold">URBAN CITY TOUR</p>
        <h4 className="text-white font-medium text-large">I miei servizi</h4>
      </CardHeader>
      <Image
        removeWrapper
        alt="Card background"
        className="z-0 w-full h-full object-cover"
        src="https://images.unsplash.com/photo-1610985725707-bb0766bf123b?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      />
    </Card>
  </div>
  );
}